import React from "react";
import Typewriter from "typewriter-effect";

// Shuffle function
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // ES6 destructuring swap
  }
  return array;
}

function Type() {
  // Shuffle strings
  const strings = shuffleArray([
    "Strategist",
    "Innovator",
    "Connector",
    "Thinker",
    "Techie",
    "Nerd",
    "Leader",
    "Entrepreneur",
    "Chess Fan",
    "Musician",
    "Dev",
    "Explorer",
    "Builder",
  ]);

  return (
    <Typewriter
      options={{
        strings: strings,
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;