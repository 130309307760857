import React, { useState, useEffect } from "react";

import 'react-chat-elements/dist/main.css'
import { Input, Button, MessageList, MessageBox } from 'react-chat-elements'
import { ChatItem } from "react-chat-elements"
import instruction from "../../Assets/content"

let defaultCommand = instruction
// `
//     Answer the following question based only on the provided context:
//     <context>
//     ${instruction}
//     </context>
// `

//!! Need to make responses shorter, and figure out what's happening with urls

function Chat() {
    let inputReference = React.createRef()
    let messageListReference = React.createRef();
    let messageListContainerRef = React.createRef()
    const [inputValue, setInputValue] = useState('');
    const [messageListArray, setMessageListArray] = useState([{
        position: 'left',
        type: 'text',
        text: "Hi, I'm Tadhg-Ai, ask me why you should hire me!"
    }])

    useEffect(() => {
        if (messageListContainerRef.current) {
            messageListContainerRef.current.scrollTop = messageListContainerRef.current.scrollHeight
        }
    })

    const Groq = require("groq-sdk"); // npm install --save groq-sdk
    var groq = new Groq({ apiKey: process.env.REACT_APP_GROQ_API_KEY, dangerouslyAllowBrowser: true });


    const apiCall = async (commandType) => {

        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error("Oops, I'm popular and overloaded at the moment. Try again later."));
            }, 60000);
        });


        let apiQuestion = inputValue
        let command

        if (commandType ==='hireQuestion') {
            // command = "Your role is to reword paragraphs, maintaining the original message length and tone, but changing the words used and the feel of the message. Return only the reworded passage when responding."
            // apiQuestion ="My experience in software development, combined with a background in psychology, gives me a unique edge in building AI tools. I leverage behavioral insights with tech solutions, leading to AI systems that are highly performant and deeply user-focused. On a more casual note - I've been told I'm fun to work with and have a positive attitude towards life."
        
            command = "Reword the provided passage, keep the passage under 50 words. Keep the meaning intact but randomly use synonyms to make the content different each time. The provided passage is:"
            apiQuestion ="My experience in software development, combined with a background in psychology, gives me a unique edge in building AI tools. I leverage behavioral insights with tech solutions, leading to AI systems that are highly performant and deeply user-focused. I've been told I'm fun to work with and have a positive attitude towards life."
        
            
        } else {
            command = defaultCommand
        }


        try {

            const completion = await Promise.race([
                groq.chat.completions.create({
                    messages: [
                        {
                            role: "system",
                            content: command
                        }, {
                            role: "user",
                            content: apiQuestion
                        },
                    ],
                    model:
                        //"llama2-70b-4096"
                        "mixtral-8x7b-32768",
                    temperature: 0.6,
                    max_tokens: 1024,
                    top_p: 0.8,
                    // stop: ".",

                }).then((chatCompletion) => {
                    setMessageListArray(messageListArray => [
                        ...messageListArray, {
                            position: 'left',
                            type: 'text',
                            text: chatCompletion.choices[0]?.message?.content || ""
                        }
                    ]);
                }),
                timeoutPromise])
        } catch (error) {
            setMessageListArray(messageListArray => [
                ...messageListArray, {
                    position: 'left',
                    type: 'text',
                    text: "My server has taken longer than 60 seconds to respond. The rate limit may have been reached, or the groq servers may have crashed. Try again in 10 minutes."
                }
            ])
        }
    }


    const handleChange = (e) => {
        setInputValue(e.target.value)
    };


    function messageSubmit() {
        if (!inputValue) {
            return
        }

        setMessageListArray(messageListArray => [
            ...messageListArray, {
                position: 'right',
                type: 'text',
                text: inputValue
            }
        ])
        if (inputValue.toString().toLowerCase().replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, "") === "why should i hire you") {
            console.log('string match - 2 sec delay then add response - should divert api call')
            apiCall("hireQuestion")
            // setTimeout(() => {
            //     setMessageListArray(messageListArray => [
            //         ...messageListArray, {
            //             position: 'left',
            //             type: 'text',
            //             text: "My experience in software development, combined with a background in psychology, gives me a unique edge in building AI tools. I leverage behavioral insights with tech solutions, leading to AI systems that are highly performant and deeply user-focused. On a more casual note - I've been told I'm fun to work with and have a positive attitude towards life."
            //         }
            //     ]);
            // }, 1000);
            inputReference.current.value = ''

            return;
        }
        apiCall()
        // setInputValue('')
        inputReference.current.value = ''
    }

    return (
        <div className="chat-container" style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
        }}>
            <div className="messagelist-container" ref={messageListContainerRef} style={{
                flexGrow: 1,
                position: 'relative',
                overflowY: 'auto',
                width: '100%',
                marginBottom: '50px',

            }}>
                <div className="message-wrapper" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end', // This keeps the initial message at the bottom
                    minHeight: '100%', // Ensure it takes at least the full height of the container
                }}>

                    <MessageList defaultValue=""
                        referance={messageListReference}
                        className='message-list'
                        lockable={true}
                        toBottomHeight={'100%'}
                        dataSource={messageListArray} />
                </div>
            </div>
            <div className="input-container" style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
            }}>
                <Input defaultValue=""
                    referance={inputReference}
                    className="chatbox-area"
                    placeholder="Type here..."
                    multiline={false}
                    onChange={handleChange}
                    onKeyPress={
                        (e) => {
                            if (e.shiftKey && e.charCode === 13) {
                                return true
                            }
                            if (e.charCode === 13) {

                                messageSubmit()
                            }
                        }
                    }
                    rightButtons={<Button
                        onClick={
                            () => messageSubmit()}
                        color='white'
                        backgroundColor='black'
                        text='Send' />}
                />
            </div>
        </div>
    )
}

export default Chat;
