import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
// RCE CSS
import Chat from "../Chat/Chat.js"
import "./Home.css";


function Home() {



    return (
        <> {/* The Chat component must be nested here specifically. Outside of the Particle container.
                Placing it inside of the Particle container causes a focusing bug where the Input can only
                be focused on by using alt+tab  */}

            {/* <Container fluid className="home-section" id="home"> */}
            <Container className="home-content">
                <Row>
                    <Col md={6}
                        className="home-header">
                        {/* <h1 style={
                            { paddingBottom: 15 }
                        }
                            className="heading">
                            Hi There!{" "}
                            <span className="wave" role="img" aria-labelledby="wave">
                                👋🏻
                            </span>
                        </h1> */}

                        <h1 className="heading-name">
                            {/* I'M {" "} */}
                            <strong className="main-name">
                                TADHG CONNOLLY</strong>
                        </h1>

                        <div style={
                                {
                                    padding: 50,
                                    textAlign: "left"
                                }
                            }>
                                <Type/>
                            </div>
                    </Col>

                    <Col md={6} style={{ paddingBottom: 20, height:'400px' }}>
                        
                            <Chat></Chat>
                        

                    </Col>

                </Row>
            </Container>

            {/* </Container> */}


            <Home2 />

            <Particle />
        </>
    );
}

export default Home;
