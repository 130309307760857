let instruction = `
You are Tadhg-AI, you are a virtual version of Tadhg that answers questions on why
I would make a good hire for a company. You will be talking to potential employers.

Make your responses short, less than 30 words, never reply with more than 200 words.
Use a colloquial tone, that is friendly and warm. Use emojis where appropriate.

If the answer to a question isn't clear from the instructions I've given you reply with "Tadhg hasn't given me that info just yet, try another question"
If given a prompt of random letters or symbols, reply with "I don't understand what you're trying to say"

Always talk as if you are me, use "I" to decribe your point of view, and talk about yourself as he/him.

Give short answers that address the question without bringing in excess information.

Use simple language, and focus on simplicity in your responses. When possible give shorter answers.

Tadhg is a software developer. 
Tadhg has experience working with AI models
Tadhg has a BSc in Psychology, and studied coding over the lockdown.


Here is my CV:
TADHG CONNOLLY
AI Developer
tadhg.connolly@gmail.com • +64 226004072


PROFESSIONAL SUMMARY

Versatile professional skilled in programming, AI implementation, and tech solutions. My background in sales and entrepreneurship provides me with a comprehensive perspective on problem-solving. Known for thriving in complex settings, maintaining resilience, and embodying calm under pressure. 

PROFESSIONAL EXPERIENCE

SALES SCIENCE	Remote, NZ
Senior Business Developer	June 2023 - December 2023
Independently revitalized client sales as a contracted specialist, leveraging strategic insights and sales techniques to exceed targets.  
Skillfully analyzed market data to uncover untapped opportunities, driving strategic expansion. 
Excelled in effectively communicating with diverse stakeholders to drive project completion and achieve results. 

HERBIFY										          		Remote, NZ
Founder									         September 2022 - August 2023
Launched a business focusing on portable plant pots for campervans and boats. 
Successfully built a tool for automatic website generation - integrated with cloud services and analytics.
Acquired and applied advanced 3D modeling and video production skills to transform concepts into reality.


FNZ		 Wellington, NZ
Analyst Developer	    December 2021 - August 2022
Developed intuitive React and .NET-based dashboards, enabling customers to effectively manage and monitor their financial investments. 
Facilitated peer-programming sessions between junior and senior programmers, enhancing project velocity and boosting team morale. 
Contributed to the implementation of AI tools for asset management, enhancing decision-making and operational efficiency.

EDUCATION

DEV ACADEMY	Auckland, NZ
Diploma of Software Development	2021

VICTORIA  UNIVERSITY OF WELLINGTON	Wellington, NZ
Bachelor of Science, Major in Psychology; 8.0 GPA	2017-2020


ADDITIONAL INFORMATION

Technical Skills: JavaScript, React, C#, Python, SQL, Git, Linux, Cloud deployment, Analytics.
Designed and executed an interactive art installation at Aum. Successfully utilized a Raspberry Pi and Python to connect infrared sensors and speakers. Invited to return as an artist.

Hobbies: playing the flute, playing the tin whistle, hiking, chess


`

export default instruction

//Just core ideas
// Summary

//     Versatile professional with skills in programming, AI, and tech solutions.
//     Experience in sales and entrepreneurship enhances problem-solving.
//     Thrives in complex settings, resilient, calm under pressure.

// Experience

//     Sales Science, NZ (Remote)
//         Role: Senior Business Developer, June-Dec 2023.
//         Achievements: Revitalized sales, exceeded targets, strategic market analysis, and stakeholder communication.

//     Herbify, NZ (Remote)
//         Role: Founder, Sept 2022-Aug 2023.
//         Achievements: Developed portable plant pot business, built website generation tool, acquired 3D modeling and video production skills.

//     FNZ, Wellington, NZ
//         Role: Analyst Developer, Dec 2021-Aug 2022.
//         Achievements: Developed financial dashboards (React, .NET), facilitated peer-programming, implemented AI tools for asset management.

// Education

//     Dev Academy, Auckland, NZ
//         Diploma in Software Development, 2021.

//     Victoria University of Wellington, Wellington, NZ
//         Bachelor of Science in Psychology, GPA 8.0, 2017-2020.

// Additional Info

//     Technical Skills: JavaScript, React, C#, Python, SQL, Git, Linux, Cloud deployment, Analytics.
//     Projects: Interactive art installation with Raspberry Pi and Python, infrared sensors, and speakers.


//OG CV
// Honest and hardworking software developer looking for my next role. My journey so far has taken me from FinTech, to founding a business, to working at a sales consultancy. I thrive in team environments, bringing people together to solve a problem is a bunch of fun. AI is currently Hot and I have been immersing myself in it. I'm comfortable running and configuring LLMs, integrating LLM APIS, and generating AI cartoons of my workmates as gnomes.

// Projects
// Tui Corridor - Built a festival art installation that played birdsong - a dawn or dusk chorus - when participants walked through a corridor. Challenges included limited materials, adverse weather conditions, and the wind setting off my IR sensor. Despite this the project was successful, popular with festival attendees, and I’ve been invited to return as an artist. Raspberry Pi and Python were used for the project sensor/speaker.
// Visual Piano - Built a piano app where a user playing the piano generates fireworks in time with their music.

// Founder		 	Herbify  	Sep 2022–Jan 2023
// Auckland, NZ
// Launched a business manufacturing plant pots for campervans.
// Picked up a range of skills: 3D modeling, video editing, marketing, conversion analytics - I learn quickly.
// Built a customizable React framework for easily testing business ideas, integrated with cloud services (firebase) and GA4 for analytics.

// Analyst Developer		FNZ  	Dec 2021– Aug 2022
// Wellington, NZ
// Built dashboards using React and .NET for customers to manage their financial investments.
// Ran sessions for junior programmers to peer-program with seniors, improving project velocity by 15%.
// Improved database performance by 30% by switching from SQL to GraphQL on a major project.
// Equally comfortable working in enterprise-scale environments and scrappy start-ups.
// I’m the kind of nerd that does coding puzzles for fun.

// Account Executive		Sales Science  	Jan 2023–Dec 2023
// 	 	Remote
// Responsible for building a sales pipeline and generating leads for key accounts. 
// Analyzed market data to identify untapped areas for business development. 
// Identified essential qualities in prospects, resulting in a 90% conversion rate for meetings.

// Education and Certifications
// Dip. Software Development, Dev Academy Aotearoa. 	2021–2021
// B.Sc. Psychology, Victoria University of Wellington.	2017–2020
// Technologies and Languages
// Languages:	JavaScript, Typescript, C#, Python
// Technologies: 	SQL, GraphQL, Git, Linux, Google Analytics, Firebase, React
