import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import herbify from "../../Assets/Projects/herbify.png";
import TadhgAi from "../../Assets/Projects/tadhgAi2.png";
import TadhgAiGif from "../../Assets/Projects/tadhgAi.gif";
import RealTimeStockChart from "../Stocks/RealTimeStockChart";
// import StockChartSelector from "../Stocks/StockChartSelector";
import familiarGif from "../../Assets/Projects/familiar.gif"
import familiarPng from "../../Assets/Projects/familiar.png"
import pianoGif from "../../Assets/Projects/piano.gif"
import pianoPng from "../../Assets/Projects/piano.png"

function Projects() {

  const [pianoHover, setPianoHover] = useState(false)
  const pianoSrc = pianoHover ? pianoGif : pianoPng

  //todo: add herbify as a project, and maybe CFS stuff, maybe bird box?
  //      add pngs and gifs for the projects

  //herbify description: 

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Projects </strong>
        </h1>
        {/* <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p> */}
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imagePng={TadhgAi}
              imageGif={TadhgAiGif}
              isBlog={false}
              title="Tadhg AI"
              description="Built a Ai chat-bot that uses my CV to tell visitors to my website about myself. Integrated with Groq and Mixtral for extreamly low latency responses."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://tadhg.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imagePng={familiarPng}
              imageGif={familiarGif}
              isBlog={false}
              title="Familiar"
              description="Led a team of four to make a cross-platform mobile app using React Native that reminds users to call family members they haven't called in a while."
              ghLink="https://github.com/TadhgConnolly/familiar"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imagePng={pianoPng}
              imageGif={pianoGif}
              isBlog={false}
              title="Visual Piano"
              description="Used P5 to apply visual effects to a piano built with React. The effects would play in time with key presses as a user played a tune on the piano."
              ghLink="https://github.com/TadhgConnolly/react-piano-component"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imagePng={herbify}
              imageGif={herbify}
              isBlog={false}
              title="Herbify"
              description="I founded a business exploring portable plant pots.For this I built a website, integrated google analytics, identified target markets and developed strategies for improving conversions."
              demoLink="https://herbify.co.nz/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <RealTimeStockChart />
          </Col> */}
          {/* 


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
