import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.svg";
import myImg from "../../Assets/TadhgKawaiPurple.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineMail
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {


  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {/* LET ME <span className="purple"> INTRODUCE </span> MYSELF */}
              <span className="purple"> ABOUT </span>

            </h1>
            <p className="home-about-body">
              So, you want to get the inside scoop on Mr. Tadhg Connolly?
              <br /><br />
              You're here to read about my skills, hear me rave about my successes, and give credence to the rumors of incredible focus and productivity—all while retaining a cool demeanor and effortless humor. <i><b className="purple">haha, haHa, HAHA</b></i> 
              <br /><br />

              <i>Deep breath</i>.
              <br /><br />
              It's all true. I'm great.
              <br /><br />
              I work hard, and I'm constantly on the hunt for efficient solutions. Collaborating towards a shared goal is immensely satisfying for me, regardless of the form it takes. Be it crafting art with embedded software, running a chess workshop, or brainstorming with colleagues about the latest puzzle to crack, I thrive in environments where creativity and teamwork intersect. My diverse experiences have equipped me with a <i><b className="purple">unique lens</b></i> to view challenges, ensuring I bring <i><b className="purple">fresh, innovative perspectives</b></i> to the table.
              <br /><br />
              If it sounds like I could be a good fit for your team, use the buttons below to reach out on LinkedIn or by email.
            </p>


            {/* <p className="home-about-body">
              Hi, I'm Tadhg. I combine
              <i>
                <b className="purple"> technical understanding </b>
              </i>

              of a software developer with
              <i>
                <b className="purple"> interpersonal skills </b>
              </i>
              gained from starting a businesses and mentoring junior developers.
              <br />
              <br />
              I originally gained a
              <i>
                <b className="purple"> BSc in Psychology </b>
              </i>
              then pivoted by gaining a
              <i>
                <b className="purple"> Diploma of Software Development </b>
              </i>
              and working as a programmer/analyst. At the start of 2023 I moved into contracting and have been enjoing the challenges and learning along the way.
              <br />
              <br />
              I thrive on challenging tasks and always push myself to grow and learn. With my background as a Software Developer, I'm comfortable using tools such as
              <i>
                <b className="purple"> C#, SQL, TypeScript, and Python, </b>
              </i>
              and use them to enhance my productivity. In my sales role I was able to leverage these skills to
              <i>
                <b className="purple"> create efficient solutions </b>
              </i>
              , saving me and my collegues time.              <br />
              <br />
              If you think I might be a good fit for your team, reach out and let's talk.

            </p> */}
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Get in Touch</h1>
            <p>
              Currently open to <span className="purple">work </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/TadhgConnolly"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/tadhgconnolly/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:tadhg.connolly@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
